import Vue from 'vue'
import Router from './router'
const vm = Vue.prototype
const e = process.env
const api_url = `${e.VUE_APP_API_PROTOCOL}://${e.VUE_APP_API_ROOT}/`
const login = (_username, _password) => {
  return new Promise((resolve, reject) => {
    vm.axios({
      method: 'post',
      url: `${api_url}oauth/token`,
      crossDomain: true,
      data: {
        grant_type: e.VUE_APP_API_GRANT_TYPE,
        client_id: e.VUE_APP_CLIENT_ID,
        client_secret: e.VUE_APP_CLIENT_SECRET,
        username: _username,
        password: _password
      }
    })
      .then(function(response) {
        console.debug(response)
        if (
          response.status === 200 &&
          response.data &&
          response.data.access_token
        ) {
          vm.$lConfig.AccessToken = response.data.access_token
          vm.$lConfig.TokenType = response.data.token_type
          request('get', 'me')
            .then(_me => {
              console.debug(_me)
              if (_me) {
                vm.$lConfig.User = JSON.stringify(_me)
              }
              resolve(true)
            })
            .catch(_notMe => {
              reject(_notMe)
            })
        }
      })
      .catch(function(error) {
        reject(error)
      })
  })
}
const request = (_method, _api, _data) => {
  console.debug(_method, _api, _data)
  return new Promise((resolve, reject) => {
    vm.axios({
      method: _method,
      url: `${api_url}${_api}`,
      crossDomain: true,
      headers: {
        Authorization: `${vm.$lConfig.TokenType} ${vm.$lConfig.AccessToken}`
      },
      data: _data,
      timeout: 260000
    })
      .then(function(response) {
        if (response && response.data) {
          resolve(response.data)
        } else {
          resolve(response)
        }
      })
      .catch(function(error) {
        console.log('ERROR')
        if (
          !vm.$lConfig.DEBUG &&
          error &&
          error.response &&
          error.response.status === 401
        ) {
          logOut()
          Router.push('auth')
        }
        reject(error)
      })
  })
}
const logOut = () => {
  vm.$lConfig.AccessToken = null
  return true
}
const isLoggedIn = () => {
  return vm.$lConfig.AccessToken ? true : false
}

export default {
  Login: login,
  Logout: logOut,
  Request: request,
  IsLoggedIn: isLoggedIn
}
