<template>
  <v-app>
    <vue-snotify></vue-snotify>
    <router-view
      name="appBar"
      @toggleMenu="
        () => {
          this.drawer = !this.drawer
        }
      "
    />
    <v-navigation-drawer
      v-model="drawer"
      :expand-on-hover="false"
      app
      fixed
      v-if="$router.currentRoute.meta.ShowMenu"
    >
      <v-list-item
        two-line
        @click="$router.push('/me')"
        :style="
          $router.currentRoute.name == 'me'
            ? {
                'background-color': $vuetify.theme.dark ? '#5C5C5C' : '#EAEAEA'
              }
            : {}
        "
      >
        <v-list-item-avatar>
          <v-icon>fas fa-user</v-icon>
        </v-list-item-avatar>
        <v-list-item-content v-if="$lConfig && $lConfig.User">
          <v-list-item-title>{{ $lConfig.User.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ $lConfig.User.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <div v-for="(item, ixmi) in menu_items" :key="ixmi">
        <v-list-item
          v-if="
            $lConfig.User && $lConfig.User.access_level >= item.access_level
          "
          link
          @click="$router.push(item.route).catch(_err => {})"
          :style="
            $router.currentRoute.name == item.name
              ? {
                  'background-color': $vuetify.theme.dark
                    ? '#5C5C5C'
                    : '#EAEAEA'
                }
              : {}
          "
        >
          <v-list-item-icon color="primary">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-badge color="red" :content="item.news" v-if="item.news > 0">
                {{ item.title }}
              </v-badge>
              <span v-else>{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <router-view name="sidebarItems"></router-view>
      <v-spacer></v-spacer>
      <v-footer fixed class="pa-0">
        <v-btn
          class="mx-auto"
          style="width: 50%"
          right
          @click="
            $vuetify.theme.dark = !$vuetify.theme.dark
            $lConfig.Dark = $vuetify.theme.dark
          "
        >
          <v-icon class="mr-2">fas fa-adjust</v-icon>
          {{ $vuetify.theme.dark ? 'LIGHT' : 'DARK' }}
        </v-btn>
        <v-btn
          class="mx-auto"
          style="width: 50%"
          right
          color="error"
          @click="
            $lConfig.AccessToken = null
            $lConfig.User = null
            $router.go()
          "
          >log out<v-icon class="ml-2">fas fa-sign-out-alt</v-icon></v-btn
        >
      </v-footer>
    </v-navigation-drawer>
    <v-content fluid style="overflow: hidden;">
      <keep-alive exclude="PriceNews">
        <router-view
          @news="updateNews"
          @toggleMenu="
            () => {
              this.drawer = !this.drawer
            }
          "
        ></router-view>
      </keep-alive>
    </v-content>
  </v-app>
</template>
<style lang="sass">
@import './../node_modules/typeface-roboto/index.css'
</style>
<script>
export default {
  name: 'App',
  components: {},
  data: () => ({
    drawer: true,
    mini: true,
    menu_items: [
      {
        title: 'Produtos',
        name: 'products',
        icon: 'fas fa-box',
        route: '/main',
        news: 0,
        access_level: 0
      },
      {
        title: 'Últimos produtos',
        name: 'history',
        icon: 'fas fa-clock',
        route: '/history',
        news: 0,
        access_level: 0
      },
      {
        title: 'Pesquisa',
        name: 'search',
        icon: 'fas fa-search',
        route: '/search',
        news: 0,
        access_level: 0
      },
      {
        title: 'Novos preços',
        name: 'pricenews',
        icon: 'fas fa-rss',
        route: '/pricenews',
        news: 0,
        access_level: 0
      },
      {
        title: 'Alertas',
        name: 'alerts',
        icon: 'fas fa-bell',
        route: '/alerts',
        news: 0,
        access_level: 0
      },
      {
        title: 'Tarefas',
        name: 'tasks',
        icon: 'fas fa-robot',
        route: '/tasks',
        news: 0,
        access_level: 777
      },
      {
        title: 'Agentes',
        name: 'agents',
        icon: 'fas fa-user-secret',
        route: '/agents',
        news: 0,
        access_level: 777
      },
      {
        title: 'Users',
        name: 'users',
        icon: 'fas fa-users',
        route: '/users',
        news: 0,
        access_level: 777
      }
    ]
  }),
  mounted() {
    setTimeout(() => {
      this.getPriceNews()
    }, 30000) //wait a few seconds before start spamming
  },
  created() {
    console.debug(this.$vuetify)
    this.$vuetify.theme.dark = this.$lConfig.Dark
  },
  methods: {
    updateNews(_obj) {
      var vm = this
      vm.menu_items.forEach(menuItem => {
        if (menuItem.name === _obj.route) {
          menuItem.news = parseInt(_obj.value)
        }
      })
    },
    getPriceNews() {
      var vm = this
      if (vm.$API.IsLoggedIn()) {
        vm.$API
          .Request('post', `products/lightNews`)
          .then(result => {
            if (result && result.data) {
              vm.menu_items.forEach(menuItem => {
                if (menuItem.name === 'pricenews') {
                  let _amount = parseInt(result.data)
                  if (menuItem.news != _amount && _amount > 0) {
                    this.$notification.show(
                      'Atualziação de preços',
                      { body: `${_amount} novos preços!` },
                      {}
                    )
                  }
                  menuItem.news = _amount
                }
              })
            }
            setTimeout(() => {
              vm.getPriceNews()
            }, 5000)
          })
          .catch(error => {
            console.debug(error)
            setTimeout(() => {
              vm.getPriceNews()
            }, 5000)
          })
      } else {
        setTimeout(() => {
          vm.getPriceNews()
        }, 300000) // 5 in 5 minutes
      }
    }
  },
  watch: {}
}
</script>
