import Vue from 'vue'
import VueRouter from 'vue-router'
import appBar from './../components/app-bar/default-app-bar.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    components: {
      default: () => import('../views/PriceNews.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'PriceNews',
      ShowMenu: true
    }
  },
  {
    path: '/me',
    name: 'me',
    components: {
      default: () => import('../views/Me.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Perfil',
      ShowMenu: true
    }
  },
  {
    path: '/users',
    name: 'users',
    components: {
      default: () => import('../views/Users.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Usuários',
      ShowMenu: true
    }
  },
  {
    path: '/main',
    name: 'products',
    components: {
      default: () => import('../views/Products.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Produtos',
      ShowMenu: true
    }
  },
  {
    path: '/history',
    name: 'history',
    components: {
      default: () => import('../views/LastProducts.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Últimos produtos',
      ShowMenu: true
    }
  },
  {
    path: '/search',
    name: 'search',
    components: {
      default: () => import('../views/Search.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Pesquisa',
      ShowMenu: true
    }
  },
  {
    path: '/tasks',
    name: 'tasks',
    components: {
      default: () => import('../views/Tasks.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Tarefas',
      ShowMenu: true
    }
  },
  {
    path: '/agents',
    name: 'agents',
    components: {
      default: () => import('../views/Agents.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Agentes',
      ShowMenu: true
    }
  },
  {
    path: '/pricenews',
    name: 'pricenews',
    components: {
      default: () => import('../views/PriceNews.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Novos preços',
      ShowMenu: true
    }
  },
  {
    path: '/alerts',
    name: 'alerts',
    components: {
      default: () => import('../views/Alerts.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Alertas',
      ShowMenu: true
    }
  },
  {
    path: '/auth',
    name: 'auth',
    components: {
      default: () => import('../views/Auth.vue')
    },
    meta: {
      ShowMenu: false
    }
  }
]
const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!Vue.prototype.$lConfig.DEBUG) {
    console.log('[ROUTER]: triggered beforeEach')
    if (to.name === 'auth' || to.name === 'landing') {
      console.log('[ROUTER]: to -> auth ... so just go')
      next()
      return
    }
    if (
      (to.name !== 'auth' || to.name !== 'landing') &&
      !Vue.prototype.$API.IsLoggedIn()
    ) {
      console.log(
        `[ROUTER]: to: ${to.name} ... but there is no AccessToken. Go back to auth`
      )
      next('auth')
      return
    }
    next()
    if (Vue.prototype.$lConfig.ForceRefresh) {
      Vue.prototype.$lConfig.ForceRefresh = null
      router.go()
    }
  } else {
    next()
    return
  }
})

export default router
