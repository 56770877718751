/* eslint-disable prettier/prettier */
export default class LocalConfig {
  constructor() { }

  get DEBUG() { return false }

  get Username() { return this._getItem('Username') }
  set Username(_val) { this._setItem('Username', _val) }

  get Dark() {
    var _dark = this._getItem('Dark')
    _dark = _dark == 'true' ? true : (_dark == 'false' ? false : true)
    return _dark
  }
  set Dark(_val) { this._setItem('Dark', _val) }

  get AccessToken() { return this._getItem('AccessToken') }
  set AccessToken(_val) { this._setItem('AccessToken', _val) }

  get RefreshToken() { return this._getItem('RefreshToken') }
  set RefreshToken(_val) { this._setItem('RefreshToken', _val) }

  get LayoutStoreProducts() { return this._getItem('LayoutStoreProducts') ? this._getItem('LayoutStoreProducts') : 'list' }
  set LayoutStoreProducts(_val) { this._setItem('LayoutStoreProducts', _val) }

  get TokenType() { return this._getItem('TokenType') }
  set TokenType(_val) { this._setItem('TokenType', _val) }

  get DeviceKey() { return this._getItem('DeviceKey') }
  set DeviceKey(_val) { this._setItem('DeviceKey', _val) }

  get User() { return this._getItem('User', true) }
  set User(_val) { this._setItem('User', _val) }

  get CurrentRoute() { return this._getItem('CurrentRoute') }
  set CurrentRoute(_val) { this._setItem('CurrentRoute', _val) }

  get ForceRefresh() { return this._getItem('ForceRefresh') }
  set ForceRefresh(_val) { this._setItem('ForceRefresh', _val) }
  
  _setItem(_key, _val) {
    this['_' + _key] = _val
    if (_val === null || _val === 'null') { localStorage.removeItem(_key) } else { localStorage.setItem(_key, _val) }
  }
  _getItem(_key, _json) {
    let _item = localStorage.getItem(_key)
    if (_json && _item) { _item = JSON.parse(_item) }
    return _item
  }
}
