/* eslint-disable */
const jsonThreeToID = (_json) => {
  var _obj = {}
  for (var _kjs in _json) {
    switch (typeOf(_json[_kjs])) {
      case 'String':
        if (_json[_kjs].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) { _json[_kjs] = new Date(_json[_kjs]) } // This is a date
        _obj[_kjs] = _json[_kjs]
        break
      case 'Null':
        _obj[_kjs] = null
        break
      case 'Undefined':
        break
      case 'Boolean':
        _obj[_kjs] = _json[_kjs]
        break
      case 'RegExp':
        break
      case 'Number':
        _obj[_kjs] = _json[_kjs]
        break
      case 'Function':
        break
      case 'Symbol':
        _obj[_kjs] = _json[_kjs]
        break
      case 'Date':
        _obj[_kjs] = _json[_kjs]
        break
      case 'Error':
        break
      case 'Array':
        _obj[_kjs] = []
        for (var _kinArr in _json[_kjs]) {
          _obj[_kjs].push(jsonThreeToID(_json[_kjs][_kinArr]))
        }
        break
      case 'Object':
        if (_json[_kjs].ID && typeOf(_json[_kjs].ID) === 'Number') { _obj[_kjs] = _json[_kjs].ID } else { _obj[_kjs] = _json[_kjs] }
        break
      default: // Unknown
        // _obj[_kjs] = _json[_kjs] // Do nothing then
        break
    }
  }
  return _obj
}
const joinTwoObjects = (_obj1, _obj2) => {
  var _obj = {}
  // the first will ovewrite the second
  for (var _kobj2 in _obj2) { _obj[_kobj2] = _obj2[_kobj2] }
  for (var _kobj1 in _obj1) { _obj[_kobj1] = _obj1[_kobj1] }
  return _obj
}
const typeOf = (value) => {
  if (value === null) { return 'Null' }
  if (typeof value === 'undefined') { return 'Undefined' }
  if (typeof value === 'boolean') { return 'Boolean' }
  if (value && typeof value === 'object' && value.constructor === RegExp) { return 'RegExp' }
  if (typeof value === 'string' || value instanceof String) { return 'String' }
  if (typeof value === 'number' && isFinite(value)) { return 'Number' }
  if (typeof value === 'function') { return 'Function' }
  if (value && typeof value === 'object' && value.constructor === Array) { return 'Array' }
  if (typeof value === 'symbol') { return 'Symbol' }
  if (value instanceof Date) { return 'Date' }
  if (value instanceof Error && typeof value.message !== 'undefined') { return 'Error' }
  if (value && typeof value === 'object' && value.constructor === Object) { return 'Object' }
  return 'Unknown'
}
const dynamicSort = (property) => {
  var sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
    return result * sortOrder
  }
}
const formatMoney = (n, c, d, t) => {
  c = isNaN(c = Math.abs(c)) ? 2 : c
  d = d === undefined ? ',' : d
  t = t === undefined ? '.' : t
  var s = n < 0 ? '-' : ''
  var i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)))
  var j = 0
  j = (j = i.length) > 3 ? j % 3 : 0
  return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
}
const replaceAll = (str, needle, replacement) => {
  str = str.toString()
  return str.split(needle).join(replacement)
}
const strToFloat = (_string, _decimals) => {
  _string = _string.toString()
  if (_decimals === undefined) { _decimals = 2 }
  var _float = _string.replace(/[^0-9.,]/g, '')
  _float = replaceAll(_float, ',', '.')
  _float = replaceAll(_float, '..', '.')
  _float = _string[0] === '-' ? parseFloat(_float) * -1 : parseFloat(_float)
  if (_decimals !== undefined && _decimals >= 0) {
    _float = formatMoney(_float, _decimals, '.', '')
  }
  return Number(_float)
}
const applyDiscount = (_price, _discount, _decimals = 2, _trueValue = false) => {
  var _val = Number(_price)
  if (_trueValue) { _val -= _discount } else { _val = _val - (_discount * _val / 100) }
  return Number(formatMoney(_val, _decimals, '.', ''))
}
const uniqID = (_base, _precision) => {
  if (!_base) { _base = 36 }
  var _date = new Date()
  var _str = ''
  var _fullYear = _date.getFullYear().toString()
  _fullYear = _fullYear.substr(1, _fullYear.length)
  _fullYear = parseInt(_fullYear)
  _str += (_fullYear).toString(_base).toUpperCase()
  _str += (_date.getMonth() + 1).toString(_base).toUpperCase()
  _str += (_date.getDate()).toString(_base).toUpperCase()
  _str += (_date.getHours()).toString(_base).toUpperCase()
  _str += (_date.getMinutes()).toString(_base).toUpperCase()
  _str += (_date.getSeconds()).toString(_base).toUpperCase()
  if (_precision) { _str += (_date.getMilliseconds()).toString(_base).toUpperCase() }
  return _str
}
const documentValidation = (_doc, resultType) => {
  if (_doc === null || _doc === undefined || _doc === '') return false;
  let _docNumbers = _doc.replace(/\D/g,'');
  let _docFormated = null;
  let _result = false;
  if (_docNumbers.length == 11) { 
      _result = validCpf(_docNumbers);
      if (_result) { _docFormated = formatCpf(_docNumbers); }
  }

  if (_docNumbers.length == 14) {
      _result = validCnpj(_docNumbers);
      if (_result) { _docFormated = formatCnpj(_docNumbers); }
  }

  if (_result == false) {
      return false;
  } else {
      switch (resultType) {
          case 1://numbers
              return _docNumbers;
              break;
          case 2://numbers formated
              return _docFormated;
              break;
          default:
              return true;
              break;
      }
  }

  function formatCpf(valor) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
  }
  function formatCnpj(valor) {
      return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
  }

  function validCpf(cpf) {
      if ( !cpf || cpf.length != 11
              || cpf == "00000000000"
              || cpf == "11111111111"
              || cpf == "22222222222" 
              || cpf == "33333333333" 
              || cpf == "44444444444" 
              || cpf == "55555555555" 
              || cpf == "66666666666"
              || cpf == "77777777777"
              || cpf == "88888888888" 
              || cpf == "99999999999" )
          return false
      var soma = 0
      var resto
      for (var i = 1; i <= 9; i++) 
          soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
      resto = (soma * 10) % 11
      if ((resto == 10) || (resto == 11))  resto = 0
      if (resto != parseInt(cpf.substring(9, 10)) ) return false
      soma = 0
      for (var i = 1; i <= 10; i++) 
          soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
      resto = (soma * 10) % 11
      if ((resto == 10) || (resto == 11))  resto = 0
      if (resto != parseInt(cpf.substring(10, 11) ) ) return false
      return true
  }
  function validCnpj(cnpj) {
      if ( !cnpj || cnpj.length != 14
              || cnpj == "00000000000000" 
              || cnpj == "11111111111111" 
              || cnpj == "22222222222222" 
              || cnpj == "33333333333333" 
              || cnpj == "44444444444444" 
              || cnpj == "55555555555555" 
              || cnpj == "66666666666666" 
              || cnpj == "77777777777777" 
              || cnpj == "88888888888888" 
              || cnpj == "99999999999999")
          return false
      var tamanho = cnpj.length - 2
      var numeros = cnpj.substring(0,tamanho)
      var digitos = cnpj.substring(tamanho)
      var soma = 0
      var pos = tamanho - 7
      for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (resultado != digitos.charAt(0)) return false;
      tamanho = tamanho + 1
      numeros = cnpj.substring(0,tamanho)
      soma = 0
      pos = tamanho - 7
      for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (resultado != digitos.charAt(1)) return false
      return true;
  }
}
const htmlFromErrors = (lstErrors) => {
  var result = ''
  lstErrors.forEach(msg => {
    if (result !== '') { result += '<br/>' }
    result += msg
  })
  return result
}
/**
 * 
 * @param {Array} _arrayOfIntOrStr Array of integer or strings
 */
const mergeArrays = (_arrayOfIntOrStr) => {
  let _obj = {}, _arr = []
  _arrayOfIntOrStr.forEach(_array => {
    _array.forEach(_it => {
      _obj[_it] = _it
    })
  })
  for (let _kobj in _obj) { _arr.push(_obj[_kobj]) }
  return _arr
}
const getLinksFromText = (_text) => {
  var expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gmi
  var matches = _text.match(expression)
  if (matches && matches.length > 0) {
    let _uniqueMatches = {}
    matches.forEach(_m => { _uniqueMatches[_m] = null })
    matches = []
    for (let _m in _uniqueMatches) { matches.push(_m) }
  }
  return matches
}
const getParamsFromURL = (_url) => {
  // FIRST we will remove the first part of the URL
  let _firstPart = _url.split('?')[0]
  let _restOfTheUrl = _url.replace(_firstPart, '')
  
  let expression = /[^&?]*?=[^&?]*/gm
  let matches = _restOfTheUrl.match(expression)
  let _params = {}
  if (matches) {
    matches.forEach(_par => {
      try {
        _params[_par.split('=')[0]] = _par.split('=')[1]
      } catch (error) {
        // ??
      }
    })
  }
  return _params
}
const getLinkName = (_url) => {
  var _s = _url.split('//')
  if (_s.length > 1) {
    return _s[1].split('/')[0].replace('www.', '')
  } else {
    return _s[0].split('/')[0].replace('www.', '')
  }
}
export default {
  JsonThreeToID: jsonThreeToID,
  JoinTwoObjects: joinTwoObjects,
  TypeOf: typeOf,
  DynamicSort: dynamicSort,
  FormatMoney: formatMoney,
  ApplyDiscount: applyDiscount,
  StrToFloat: strToFloat,
  UniqID: uniqID,
  DocumentValidation: documentValidation,
  MergeArrays: mergeArrays,
  HtmlFromErrors: htmlFromErrors,
  GetLinksFromText: getLinksFromText,
  GetParamsFromURL: getParamsFromURL,
  GetLinkName: getLinkName
}
