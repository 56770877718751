var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('vue-snotify'),_c('router-view',{attrs:{"name":"appBar"},on:{"toggleMenu":function () {
        this$1.drawer = !this$1.drawer
      }}}),(_vm.$router.currentRoute.meta.ShowMenu)?_c('v-navigation-drawer',{attrs:{"expand-on-hover":false,"app":"","fixed":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{style:(_vm.$router.currentRoute.name == 'me'
          ? {
              'background-color': _vm.$vuetify.theme.dark ? '#5C5C5C' : '#EAEAEA'
            }
          : {}),attrs:{"two-line":""},on:{"click":function($event){return _vm.$router.push('/me')}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fas fa-user")])],1),(_vm.$lConfig && _vm.$lConfig.User)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$lConfig.User.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$lConfig.User.email))])],1):_vm._e()],1),_c('v-divider'),_vm._l((_vm.menu_items),function(item,ixmi){return _c('div',{key:ixmi},[(
          _vm.$lConfig.User && _vm.$lConfig.User.access_level >= item.access_level
        )?_c('v-list-item',{style:(_vm.$router.currentRoute.name == item.name
            ? {
                'background-color': _vm.$vuetify.theme.dark
                  ? '#5C5C5C'
                  : '#EAEAEA'
              }
            : {}),attrs:{"link":""},on:{"click":function($event){_vm.$router.push(item.route).catch(function (_err) {})}}},[_c('v-list-item-icon',{attrs:{"color":"primary"}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[(item.news > 0)?_c('v-badge',{attrs:{"color":"red","content":item.news}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('span',[_vm._v(_vm._s(item.title))])],1)],1)],1):_vm._e()],1)}),_c('router-view',{attrs:{"name":"sidebarItems"}}),_c('v-spacer'),_c('v-footer',{staticClass:"pa-0",attrs:{"fixed":""}},[_c('v-btn',{staticClass:"mx-auto",staticStyle:{"width":"50%"},attrs:{"right":""},on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark
          _vm.$lConfig.Dark = _vm.$vuetify.theme.dark}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-adjust")]),_vm._v(" "+_vm._s(_vm.$vuetify.theme.dark ? 'LIGHT' : 'DARK')+" ")],1),_c('v-btn',{staticClass:"mx-auto",staticStyle:{"width":"50%"},attrs:{"right":"","color":"error"},on:{"click":function($event){_vm.$lConfig.AccessToken = null
          _vm.$lConfig.User = null
          _vm.$router.go()}}},[_vm._v("log out"),_c('v-icon',{staticClass:"ml-2"},[_vm._v("fas fa-sign-out-alt")])],1)],1)],2):_vm._e(),_c('v-content',{staticStyle:{"overflow":"hidden"},attrs:{"fluid":""}},[_c('keep-alive',{attrs:{"exclude":"PriceNews"}},[_c('router-view',{on:{"news":_vm.updateNews,"toggleMenu":function () {
            this$1.drawer = !this$1.drawer
          }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }