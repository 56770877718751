/* eslint-disable */
import Fuse from 'fuse.js'
import f from './../scripts/functions'
const jsearch = require('searchjs')
export default {
  install (Vue, options) {
    Vue.prototype.$IXDB = new Vue({
      name: 'IXDB',
      data () {
        return {
          DB: null
        }
      },
      methods: {
        SaveResponse (_table, _response) {
          if (_response && _response.data && _response.data.length > 0) { return this.SaveArrayOfObject(_table, _response.data) }
        },
        OverwriteResponse (_table, _response) {
          return new Promise ((resolve, reject) => {
            if (_response && _response.data && _response.data.length > 0) {
              this.ClearTable(_table).then(() => {
                this.SaveArrayOfObject(_table, _response.data).then((_returningval) => {
                  resolve(_returningval)
                }).catch((_error) => { reject(_error)})
              }).catch((error) => {
                console.debug(error)
                // If the table does not exist yet, it will fail, in this case, we don't care, jsut save them all
                this.SaveArrayOfObject(_table, _response.data).then((_returningval) => {
                  resolve(_returningval)
                }).catch((_error) => { reject(_error)})
              })
            } else {
              resolve(true)
            }
          })
        },
        ClearTable (_table) {
          var vm = this
          return new Promise((resolve, reject) => {
            openDatabase(options.databaseName).then((db) => {
              var _transaction = null
              try {
                transaction = db.transaction([_table], 'readwrite')
              } catch (e) {
                reject(e)
                return 
              }
              // transaction.oncomplete = (event) => { resolve(true) }
              // transaction.onerror = (event) => { reject(event) }
              var _objectStore = transaction.objectStore(_table)
              var _request = _objectStore.clear()
              _request.onsuccess = (event) => {
                resolve(true)
              }
              _request.onerror = (error) => { reject(error) }
            })
          })
        },
        ExportLauout () {
          var tableList = generateTableList()
          var layout = {}
          tableList.forEach(t => {
            layout[t.Name] = {}
            t.Indexes.forEach(ix => {
              if (ix.layoutInformation) {
                layout[t.Name][ix.ik] = ix.layoutInformation
              } else {
                layout[t.Name][ix.ik] = {}
              }
            })
          })
          return JSON.stringify(layout)
        },
        SaveObject (_table, _object) {
          var vm = this
          return new Promise((resolve, reject) => {
            openDatabase(options.databaseName).then((db) => {
              var transaction = null
              try { transaction = db.transaction([_table], 'readwrite') } catch (e) { vm.ShowErrorMessage(e); reject(e); return }
              transaction.oncomplete = (event) => { resolve(true) }
              transaction.onerror = (event) => { reject(event) }
              var objectStore = transaction.objectStore(_table)
              var _key = _object.KEY
              if (_object.ID) { _key = _object.ID }
              var requestGet = objectStore.get(_key)
              requestGet.onerror = (event) => { console.debug(event) }
              requestGet.onsuccess = (event) => {
                var data = event.target.result
                var objectStoreRequest = null
                if (data === undefined) { objectStoreRequest = objectStore.add(_object) } else { objectStoreRequest = objectStore.put(_object) }
                objectStoreRequest.onerror = (event) => {
                  reject(event)
                }
                objectStoreRequest.onsuccess = (event) => {
                  resolve(event)
                }
              }
            }).catch((e) => { reject(e) })
          })
        },
        SaveArrayOfObject (_table, _arrayOfObject) {
          var vm = this
          return new Promise((resolve, reject) => {
            openDatabase(options.databaseName).then((db) => {
              var transaction = null
              try { transaction = db.transaction([_table], 'readwrite') } catch (e) { vm.ShowErrorMessage(e); reject(e); return }
              transaction.oncomplete = (event) => { resolve(true) }
              transaction.onerror = (event) => { reject(event) }
              var objectStore = transaction.objectStore(_table)
              let _counter = 0
              _arrayOfObject.forEach(obj => {
                _counter += 1
                var _key = obj.KEY
                if (obj.ID) { _key = obj.ID }
                var listOfTables = generateTableList()
                listOfTables.forEach(_t => { if (_t.Name === _table) { _key = obj[_t.Indexes[0].ik] } })
                var requestGet = objectStore.get(_key)
                requestGet.onerror = (event) => { console.debug(event) }
                requestGet.onsuccess = (event) => {
                  var data = event.target.result
                  var objectStoreRequest = null
                  if (data === undefined) { objectStoreRequest = objectStore.add(obj) } else { objectStoreRequest = objectStore.put(obj) }
                  objectStoreRequest.onerror = (event) => {}
                  objectStoreRequest.onsuccess = (event) => { console.log('SAVED DATA - ' + _counter) }
                }
              })
            })
          })
        },
        SaveTables (_tables) {
          var vm = this
          return new Promise((resolve, reject) => {
            openDatabase(options.databaseName).then((db) => {
              var totalNumberOfTables = Object.keys(_tables).length
              var tablesProcessed = 0
              var objHelper = {}
              var arrOfTables = []
              if (_tables.Product) { arrOfTables.push('Product') }
              for (var _tbl in _tables) { if (_tbl !== 'Product') { arrOfTables.push(_tbl) } }
              arrOfTables.forEach(tbl => { foreachTableInTables(tbl) })
              // if (_tables.Stock) { foreachTableInTablesSTOCK('Stock') }
              function foreachTableInTables (_table) {
                objHelper[_table] = {}
                try { objHelper[_table].transaction = db.transaction([_table], 'readwrite') } catch (e) { vm.ShowErrorMessage(e); reject(e); return }
                objHelper[_table].transaction.oncomplete = (event) => {
                  tablesProcessed += 1
                  if (totalNumberOfTables === tablesProcessed) {
                    resolve(true)
                  }
                }
                objHelper[_table].transaction.onerror = (event) => {
                  tablesProcessed += 1
                  if (totalNumberOfTables === tablesProcessed) {
                    reject(event)
                  }
                }
                objHelper[_table].objectStore = objHelper[_table].transaction.objectStore(_table)
                _tables[_table].forEach(obj => {
                  var _key = obj.KEY
                  if (obj.ID) { _key = obj.ID }
                  var listOfTables = generateTableList()
                  listOfTables.forEach(_t => {
                    if (_t.Name === _table) {
                      _key = obj[_t.Indexes[0].ik]
                    }
                  })
                  var requestGet = objHelper[_table].objectStore.get(_key)
                  requestGet.onerror = (event) => { console.debug(event) }
                  requestGet.onsuccess = (event) => {
                    var _tmpTableName = event.srcElement.source.name
                    var data = event.target.result
                    objHelper[_tmpTableName].objectStoreRequest = null
                    if (data === undefined) {
                      objHelper[_tmpTableName].objectStoreRequest = objHelper[_tmpTableName].objectStore.add(obj)
                    } else {
                      objHelper[_tmpTableName].objectStoreRequest = objHelper[_tmpTableName].objectStore.put(obj)
                    }
                    objHelper[_tmpTableName].objectStoreRequest.onerror = (event) => {}
                    objHelper[_tmpTableName].objectStoreRequest.onsuccess = (event) => {}
                  }
                })
              }
              /*
              function foreachTableInTablesSTOCK (_table) {
                var vm = this
                objHelper.StockTemp = {}
                try { objHelper.StockTemp.transaction = db.transaction([_table], 'readwrite') } catch (e) { vm.ShowErrorMessage(e); reject(e); return }
                objHelper.StockTemp.transaction.oncomplete = (event) => { }
                objHelper.StockTemp.transaction.onerror = (event) => { }
                objHelper.StockTemp.objectStore = objHelper.StockTemp.transaction.objectStore('Product')
                _tables[_table].forEach(obj => {
                  var _key = obj.KEY
                  if (obj.ID) { _key = obj.ID }
                  var listOfTables = generateTableList()
                  listOfTables.forEach(_t => { if (_t.Name === _table) { _key = obj[_t.Indexes[0].ik] } })
                  var requestGet = objHelper.StockTemp.objectStore.get(_key)
                  requestGet.onerror = (event) => { console.debug(event) }
                  requestGet.onsuccess = (event) => {
                    var data = event.target.result
                    objHelper.StockTemp.objectStoreRequest = null
                    if (data !== undefined) {
                      console.log('NOT UNDEFINED -::::::::::::::???????????')
                      console.debug(data)
                      obj.QtdPorEmpresa = {}
                      obj.Lotes.forEach(lote => { if (obj.QtdPorEmpresa[lote.Empresa]) { obj.QtdPorEmpresa[lote.Empresa] += lote.QtdLivre } else { obj.QtdPorEmpresa[lote.Empresa] = lote.QtdLivre } })
                      data.Stocks = { QtdPorEmpresa: obj.QtdPorEmpresa }
                      objHelper.StockTemp.objectStoreRequest = objHelper.StockTemp.objectStore.put(data)
                    }
                    objHelper.StockTemp.objectStoreRequest.onerror = (event) => { }
                    objHelper.StockTemp.objectStoreRequest.onsuccess = (event) => { }
                  }
                })
              }
              */
            })
          })
        },
        SearchByModel (lstObjects, _model, _sort) {
          var _listOfMatches = lstObjects
          if (_model) { _listOfMatches = matchesObjects(lstObjects) }
          if (_sort) { _listOfMatches.sort(f.DynamicSort(_sort)) }
          return _listOfMatches
          function matchesObjects (_obj) {
            if (f.TypeOf(_obj) === 'Array') {
              var _matches = []
              _obj.forEach(obj => { if (jsearch.matchObject(obj, _model)) { _matches.push(obj) } })
              return _matches
            } else {
              if (jsearch.matchObject(_obj, _model)) {
                return _obj
              } else {
                return null
              }
            }
          }
        },
        FuseSearch(lstObjects, _searchString, _keys, _sort) {
          if (!_searchString || _searchString === "") return lstObjects
          var _fOpt = {
            shouldSort: true,
            tokenize: false,
            matchAllTokens: false,
            findAllMatches: false,
            includeMatches: true,
            includeScore: true,
            threshold: 0.35,
            location: 0,
            distance: 100,
            maxPatternLength: 100,
            minMatchCharLength: 1,
            keys: _keys
          }
          var fuse = new Fuse(lstObjects, _fOpt)
          var _lstSearchResult = fuse.search(_searchString)
          console.debug(_lstSearchResult)
          var _returningList = _lstSearchResult
          // _lstSearchResult.forEach(fs => { _returningList.push(fs.item) })
          return _returningList
        },
        Search (_table, _ix, _val, _maxIndexes, _page, _sort, _model, _fuse) {
          var vm = this
          return new Promise((resolve, reject) => {
            openDatabase(options.databaseName).then((db) => {
              var _transaction = null
              try { _transaction = db.transaction(_table) } catch (e) { vm.ShowErrorMessage(e); reject(e); return }
              var _objectStore = _transaction.objectStore(_table)
              if (_val && _val !== null && _val !== '') {
                if (_ix && _ix !== null) {
                  getFuseItemsFromIndex(_objectStore).then((_lstFuse) => {
                    if (_lstFuse && _lstFuse.length === 0) {
                      resolve(returningObject(_lstFuse))
                    } else {
                      openObjectsFromFuseResultList(_lstFuse, _objectStore).then((result) => {
                        resolve(returningObject(result))// RETURNING VALUE
                      }).catch((error) => { reject(error) })
                    }
                  })
                } else {
                  openObject(_val, _objectStore).then((result) => {
                    resolve(returningObject(result))// RETURNING VALUE
                  }).catch((error) => { reject(error) })
                }
              } else {
                var _request = _objectStore.getAll()
                _request.onsuccess = (event) => {
                  resolve(returningObject(event.target.result))
                }
                _request.onerror = (error) => { reject(error) }
              }
            })
          })
          function getFuseItemsFromIndex (_objectStore) {
            return new Promise((resolve, reject) => {
              var _index = _objectStore.index(_ix)
              var _lstKeyToSearch = []
              var _lstSearchResult = []
              _index.openKeyCursor().onsuccess = (event) => {
                var cursor = event.target.result
                if (cursor) {
                  if (_fuse) {
                    _lstKeyToSearch.push({ KEY: cursor.primaryKey, search: cursor.key })
                  } else if (cursor.key === _val) {
                    _lstSearchResult.push({ item: { KEY: cursor.primaryKey, search: cursor.key } })
                  }
                  cursor.continue()
                } else {
                  if (_fuse) {
                    var _fOpt = {
                      shouldSort: true,
                      tokenize: true,
                      matchAllTokens: true,
                      findAllMatches: true,
                      includeScore: true,
                      threshold: 0.4,
                      location: 0,
                      distance: 100,
                      maxPatternLength: 100,
                      minMatchCharLength: 1,
                      keys: ['KEY', 'search']
                    }
                    var fuse = new Fuse(_lstKeyToSearch, _fOpt)
                    _lstSearchResult = fuse.search(_val)
                  }
                  resolve(_lstSearchResult)
                }
              }
            })
          }
          function openObjectsFromFuseResultList (_lstFuseResult, _objectStore) {
            var _processedItems = 0
            return new Promise((resolve, reject) => {
              for (var _kIfr in _lstFuseResult) {
                var _key = _lstFuseResult[_kIfr].item.KEY
                getFromObjS(_objectStore, _key, _kIfr).then(() => {
                  _processedItems += 1
                  if (_processedItems === _lstFuseResult.length) { resolve(_lstFuseResult) }
                }).catch(() => {
                  _processedItems += 1
                  if (_processedItems === _lstFuseResult.length) { resolve(_lstFuseResult) }
                })
              }
            })
            function getFromObjS (_objS, _key, _index) {
              return new Promise((resolve, reject) => {
                var _request = _objS.get(_key)
                _request.onsuccess = (event) => {
                  event.target.result.score = _lstFuseResult[_index].score
                  _lstFuseResult[_index] = event.target.result
                  resolve(true)
                }
                _request.onerror = (error) => {
                  reject(error)
                }
              })
            }
          }
          function openObject (_key, _objectStore) {
            return new Promise((resolve, reject) => {
              var _request = _objectStore.get(_key)
              _request.onsuccess = (event) => {
                resolve(event.target.result)
              }
              _request.onerror = (error) => {
                reject(error)
              }
            })
          }
          function returningObject (lstObjects) {
            if (_sort) { lstObjects.sort(f.DynamicSort(_sort)) }
            if (_model) { lstObjects = matchesObjects(lstObjects) }
            return paginate(lstObjects)
            function matchesObjects (lstObjects) {
              if (f.TypeOf(lstObjects) === 'Array') {
                var _lstProd = []
                for (var _kobj in lstObjects) {
                  var obj = lstObjects[_kobj]
                  if (jsearch.matchObject(obj, _model)) {
                    _lstProd.push(obj)
                  }
                }
                // var _lstProd = jsearch.matchArray(lstObjects, _model)
                return _lstProd
              } else {
                if (jsearch.matchObject(lstObjects, _model)) {
                  return lstObjects
                } else {
                  return null
                }
              }
            }
          }
          function paginate (lstObjects) {
            var _pagination = null
            if (_maxIndexes) {
              if (_page) {
                var _totalPages = Math.ceil(lstObjects.length / _maxIndexes)
                var _currentPage = _page
                var _nextPage = _currentPage + 1
                if (_currentPage >= _totalPages) { _nextPage = null }
                var _previousPage = _currentPage - 1
                if (_currentPage <= 1) { _previousPage = null }
                _pagination = {
                  total: _totalPages,
                  current: _currentPage,
                  next: _nextPage,
                  previous: _previousPage
                }
                _page -= 1 // Because we start at page 0 (internally)
                return {
                  data: lstObjects.slice((_page * _maxIndexes), ((_page * _maxIndexes) + _maxIndexes)),
                  pagination: _pagination
                }
              } else {
                return {
                  data: lstObjects.slice(0, _maxIndexes),
                  pagination: _pagination
                }
              }
            } else {
              return {
                data: lstObjects,
                pagination: _pagination
              }
            }
          }
        },
        Delete (_table, _key) {
          var vm = this
          return new Promise((resolve, reject) => {
            openDatabase(options.databaseName).then((db) => {
              var transaction = null
              try { transaction = db.transaction([_table], 'readwrite') } catch (e) { vm.ShowErrorMessage(e); reject(e); return }
              transaction.oncomplete = (event) => { resolve(true) }
              transaction.onerror = (event) => { reject(event) }
              var objectStore = transaction.objectStore(_table)
              objectStore.delete(_key)
            })
          })
        },
        SearchObject (_table, _string) { // DEPRECATED
          openDatabase(options.databaseName).then((db) => {
            var _transaction = db.transaction(_table)
            var _objectStore = _transaction.objectStore(_table)
            _objectStore.openCursor().onsuccess = (event) => {
              var cursor = event.target.result
              if (cursor) {
                console.debug(cursor)
                cursor.continue()
              } else {
                console.log('No more entries!')
              }
            }
          })
        },
        SearchObjectByKey (_table, _string) { // DEPRECATED
          openDatabase(options.databaseName).then((db) => {
            var _transaction = db.transaction(_table)
            var _objectStore = _transaction.objectStore(_table)
            _objectStore.openKeyCursor().onsuccess = (event) => {
              var cursor = event.target.result
              if (cursor) {
                console.debug(cursor)
                cursor.continue()
              } else {
                console.log('No more entries!')
              }
            }
          })
        },
        GetAll (_table) { // DEPRECATED
          return new Promise((resolve, reject) => {
            openDatabase(options.databaseName).then((db) => {
              var _transaction = db.transaction(_table)
              var _objectStore = _transaction.objectStore(_table)
              _objectStore.getAll().onsuccess = (event) => {
                resolve(event.target.result)
              }
            })
          })
        },
        GetFromIndex (_table, _ix, _val) { // DEPRECATED
          return new Promise((resolve, reject) => {
            openDatabase(options.databaseName).then((db) => {
              var _transaction = db.transaction(_table)
              var _objectStore = _transaction.objectStore(_table)
              var _index = _objectStore.index(_ix)
              var _lstResult = []
              _index.openCursor(IDBKeyRange.only(_val)).onsuccess = (event) => {
                var cursor = event.target.result
                if (cursor) {
                  cursor.source.objectStore.get(cursor.primaryKey).onsuccess = (event2) => {
                    // console.debug(event2.target.result)
                    _lstResult.push(event2.target.result)
                    cursor.continue()
                    // _updateValues(event2.target.result)
                  }
                } else {
                  resolve(_lstResult)
                }
              }
            })
          })
        },
        ShowErrorMessage (error) {
          var vm = this
          vm.$snotify.error('data-storage error: ' + error.message)
        }
      }
    })
  }
}
const openDatabase = (_dbName) => {
  var request = window.indexedDB.open(_dbName, 1)
  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      reject(event)
    }
    request.onsuccess = (event) => {
      resolve(request.result)
    }
    request.onupgradeneeded = (event) => {
      var db = event.target.result
      var transaction = event.target.transaction
      db.onerror = (event) => { }
      var listOfTables = generateTableList()
      updateTables(listOfTables)
      function updateTables (_lstOfTables) {
        _lstOfTables.forEach(table => {
          // try to open the table
          var objectStore = null
          try {
            objectStore = transaction.objectStore(table.Name)
          } catch (error) {
            objectStore = db.createObjectStore(table.Name, { keyPath: table.Indexes[0].ik })
          }
         table.Indexes.forEach(index => {
            if (table.Indexes[0].ik !== index.ik) {
              // Prepare params
              let _par = index.par ? index.par : { unique: index.unique ? index.unique : false }
              let _ix = index.ix ? index.ix : index.ik
              let _key = index.ik

              // Now check if the index exists
              if (!objectStore.indexNames.contains(index.ik)) {
                // The store does not contain an index with that name
                // Can create it here if you want
                objectStore.createIndex(_ix, _key, _par)
              } else {
                // TODO: open its properties and change it according to the right stuff to do
                // var index = objectStore.index(index.ik)
                // if(index.multiEntry) { }
                // if(index.unique) { }
              }
            }
          })
        })
      }
    }
  })
}
const generateTableList = () => {
  var listOfTables = []
  listOfTables.push({ Name: 'Configurations', Indexes: [ { ik: 'id', unique: true } ] })
  return listOfTables
}
